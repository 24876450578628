// Packages
import React, { useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
// Utils
import { logout } from "../utils";

const Logout = React.memo((props) => {
  const rootPathname = useMemo(() => "/", []);
  const history = useHistory();

  useEffect(() => {
      axios
        .post("/api/auth/logout")
        .finally(() => {
          logout();
          history.replace(rootPathname);
        });
  }, [history, rootPathname]);

  return (
    <Spinner color="dark" style={{
      position: "absolute",
      top: "calc(50% - 3rem)",
      left: "calc(50% - 3rem)",
      width: "6rem",
      height: "6rem"
    }} />
  );
});

export default Logout;
