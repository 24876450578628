// Packages
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledTooltip,
  InputGroup,
  FormFeedback
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import {
  BsDownload,
  BsTrashFill,
  BsFolder,
  BsFileEarmarkPdf,
  BsPencilFill,
  BsFiles,
  BsScissors,
  BsClipboardCheck,
  BsFileEarmarkPlus,
  BsFolderPlus,
  BsXLg,
  BsArrowBarUp,
  BsSearch
} from "react-icons/bs";
// Utils
import { catchHandler, getRequestHeaders, processUserName, userHasAccess, userRoles } from "../utils";
// Styling
import "./Files.css";

const Files = React.memo((props) => {
  const noOp = useMemo(() => () => {}, []);
  const isAdminFlag = useMemo(() => userHasAccess([userRoles.ADMIN]), []);
  const [loading, setLoading] = useState(true);
  const [loadingActions, setLoadingActions] = useState(false);
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [filesInput, setFilesInput] = useState([]);
  const [dirInput, setDirInput] = useState("");
  const [renameInput, setRenameInput] = useState([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [currentPathNames, setCurrentPathNames] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set([]));
  const [copyFlag, setCopyFlag] = useState(null);
  const [moveFlag, setMoveFlag] = useState(null);
  const totalSize = useMemo(() => files.reduce((acc, file) => acc + file.size, 0), [files]);
  const parentId = useMemo(() => currentPath.length ? currentPath[currentPath.length - 1] : '', [currentPath]);
  const [searchValue, setSearchValue] = useState('');
  const [searchFlag, setSearchFlag] = useState(false);
  const [searchError, setSearchError] = useState('');

  const listFiles = useCallback((pathComponents, search = "") => {
    setLoading(true);

    axios
      .get("/api/files/list-files", getRequestHeaders({parents: JSON.stringify(pathComponents), search}))
      .then((res) => {
        const { data = [] } = res;
        setFiles(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, [])

  useEffect(() => {
    listFiles([])
  }, []);

  const onDownloadClick = useCallback(
    (id, originalname) => {
      setLoadingActions(true);

      axios
        .get(
          `/api/files/download-file/${id}`,
          getRequestHeaders({ requestDownloadFilesFlag: true })
        )
        .then((res) => {
          const type = res.headers["content-type"];
          const blob = new Blob([res.data], { type, encoding: "UTF-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = originalname;
          link.click();
          link.remove();
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    []
  );

  const onFolderHistoryClick = useCallback((id, moveFlag, copyFlag) => {
    setCurrentPath(prevPath => {
      const currentPath = [...prevPath];
      currentPath.splice(id);
      setCurrentPathNames(prevPathNames => {
        const currentPathNames = [...prevPathNames];
        currentPathNames.splice(id);
        listFiles(currentPath);
        return currentPathNames;
      });
      return currentPath;
    });
    if (!moveFlag && !copyFlag) {
      setSelectedRows(new Set([]));
    }
    setSearchValue("");
    setSearchFlag(false);
  }, []);

  const onFolderDoubleClick = useCallback((type, _id, originalname, moveFlag, copyFlag) => {
    if (type === "dir") {
      setCurrentPath(prevPath => {
        const currentPath = [...prevPath];
        currentPath.push(_id);
        setCurrentPathNames(prevPathNames => {
          const currentPathNames = [...prevPathNames];
          currentPathNames.push(originalname);
          listFiles(currentPath);
          return currentPathNames;
        });
        return currentPath;
      });
      if (!moveFlag && !copyFlag) {
        setSelectedRows(new Set([]));
      }
    }
  }, []);

  const onParentDoubleClick = useCallback((moveFlag, copyFlag) => {
    setCurrentPath(prevPath => {
      const currentPath = [...prevPath];
      currentPath.pop();
      setCurrentPathNames(prevPath => {
        const currentPathNames = [...prevPath];
        currentPathNames.pop();
        listFiles(currentPath);
        return currentPathNames;
      });
      return currentPath;
    });
    if (!moveFlag && !copyFlag) {
      setSelectedRows(new Set([]));
    }
  }, []);

  const onSelectRow = useCallback((index) => {
    setSelectedRows((prevRows) => {
      const newRows = new Set([...prevRows]);

      if (newRows.has(index)) {
        newRows.delete(index);
      } else {
        newRows.add(index);
      }

      return newRows;
    });
  }, []);

  const onSelectAllRows = useCallback(() => {
    setSelectedRows((prevRows) => {
      const newRows = new Set([]);

      if (prevRows.size !== files.length) {
        for (let file of files) {
          newRows.add(file._id);
        }
      }

      return newRows;
    })
  }, [files]);

  const getRows = useCallback(
    () =>
      files.length ? files.map(
        ({ _id, type, user, originalname, size, parents }, id) => (
          <tr key={`files-row-${id}`} onDoubleClick={searchFlag || selectedRows.has(_id) ? noOp : onFolderDoubleClick.bind(null, type, _id, originalname, moveFlag, copyFlag)}>
            {
              !searchFlag ? (
                <td>
                  <Input className="select-row" type="checkbox" disabled={Boolean(moveFlag || copyFlag)} onChange={onSelectRow.bind(null, _id)} checked={selectedRows.has(_id)} />
                </td>
              ) : null
            }
            <td colSpan={searchFlag ? 2 : 1}>
              {type === "dir" ? <BsFolder /> : <BsFileEarmarkPdf />} {originalname} ({Math.round(size / 1000)}Kb)
              {searchFlag ? (<>[ /home{parents.length ? `/${Object.values(parents).map(parent => parent.originalname).join("/")}` : ""} ]</>) : null}
            </td>
            <td>{processUserName(user)}</td>
            <td className="text-center">
              {
                type === "file" ? (
                  <Button
                    disabled={loadingActions}
                    color="info"
                    size="sm"
                    onClick={onDownloadClick.bind(null, _id, originalname)}
                  >
                    <BsDownload />
                  </Button>
                ) : null
              }
            </td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="warning"
                size="sm"
                onClick={openRenameFile.bind(null, _id, originalname)}
              >
                <BsPencilFill />
              </Button>
            </td>
          </tr>
        )
      ) : !currentPath.length ? (
        <tr key="files-row-no-result">
          <td colSpan={5} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ) : null,
    [currentPath, loadingActions, files, moveFlag, copyFlag, selectedRows, searchFlag]
  );

  const [addFilesOpen, setAddFilesOpen] = useState(false);

  const openAddFiles = useCallback(() => {
    setAddFilesOpen(true);
  }, []);

  const closeAddFiles = useCallback(() => {
    setAddFilesOpen(false);
  }, []);

  const toggleAddFiles = useCallback(() => {
    setAddFilesOpen(prevState => !prevState);
  }, []);

  const onFilesInput = useCallback((event) => {
    const { target = {} } = event;
    const { files } = target;
    setFilesInput(files);
  }, []);

  const onSubmitAddFiles = useCallback(
    (event) => {
      event.preventDefault();

      setLoadingActions(true);

      const formData = new FormData();
      formData.append("parents", JSON.stringify(currentPath));
      for (let file of filesInput) {
        formData.append("files", file);
      }

      axios
        .post(
          "/api/files/create-file",
          formData,
          getRequestHeaders({ requestHasFilesFlag: true })
        )
        .then((res) => {
          toast.success("Documentele au fost salvate cu succes");
          listFiles(currentPath);
          closeAddFiles();
        })
        .catch((err) => {
          catchHandler(history, err);
        })
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [history, filesInput, listFiles, currentPath, closeAddFiles]
  );

  const [addDirOpen, setAddDirOpen] = useState(false);

  const openAddDir = useCallback(() => {
    setAddDirOpen(true);
  }, []);

  const closeAddDir = useCallback(() => {
    setAddDirOpen(false);
  }, []);

  const toggleAddDir = useCallback(() => {
    setAddDirOpen(prevState => !prevState);
  }, []);

  const onDirInput = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setDirInput(value);
  }, []);

  const onSubmitAddDir = useCallback(
    (event) => {
      event.preventDefault();

      setLoadingActions(true);

      axios
        .post(
          "/api/files/create-dir",
          { originalname: dirInput, parents: currentPath },
          getRequestHeaders()
        )
        .then((res) => {
          toast.success("Directorul a fost adaugat cu succes");
          listFiles(currentPath);
          closeAddDir();
        })
        .catch((err) => {
          catchHandler(history, err);
        })
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [history, dirInput, listFiles, currentPath, closeAddDir]
  );

  const [renameFileOpen, setRenameFileOpen] = useState("");

  const openRenameFile = useCallback((fileId, originalname) => {
    setRenameFileOpen(fileId);
    setRenameInput(originalname);
  }, []);

  const closeRenameFile = useCallback(() => {
    setRenameFileOpen("");
  }, []);

  const onRenameInput = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setRenameInput(value);
  }, []);

  const onSubmitRenameFile = useCallback(
    (event) => {
      event.preventDefault();

      setLoadingActions(true);

      axios
        .patch(
          "/api/files/rename-file",
          { fileId: renameFileOpen, name: renameInput },
          getRequestHeaders()
        )
        .then((res) => {
          toast.success("Fisierul a fost redenumit cu succes");
          listFiles(currentPath);
          closeRenameFile();
        })
        .catch((err) => {
          catchHandler(history, err);
        })
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [history, renameFileOpen, renameInput, listFiles, currentPath, closeRenameFile]
  );

  const onCopyClick = useCallback((parentId) => {
    setCopyFlag({ _id: parentId });
  }, []);

  const onMoveClick = useCallback((parentId) => {
    setMoveFlag({ _id: parentId });
  }, []);

  const onPasteClick = useCallback((copyFlag, moveFlag, fileIds, destinationParents) => {
    setLoadingActions(true);

    if (copyFlag) {
      axios
        .post(
          "/api/files/copy-files",
          { parentId: copyFlag._id, fileIds, destinationParents },
          getRequestHeaders()
        )
        .then((res) => {
          toast.success("Fisierele au fost copiate cu succes");
          listFiles(destinationParents);
          setCopyFlag(null);
          setSelectedRows(new Set([]));
        })
        .catch((err) => {
          catchHandler(history, err);
        })
        .finally(() => {
          setLoadingActions(false);
        });
    }

    if (moveFlag) {
      axios
        .post(
          "/api/files/move-files",
          { parentId: moveFlag._id, fileIds, destinationParents },
          getRequestHeaders()
        )
        .then((res) => {
          toast.success("Fisierele au fost mutate cu succes");
          listFiles(destinationParents);
          setMoveFlag(null);
          setSelectedRows(new Set([]));
        })
        .catch((err) => {
          catchHandler(history, err);
        })
        .finally(() => {
          setLoadingActions(false);
        });
    }
  }, []);

  const onCancelClick = useCallback(() => {
    setCopyFlag(null);
    setMoveFlag(null);
  }, []);

  const onRemoveClick = useCallback((fileIds) => {
    setLoadingActions(true);

    axios
      .post("/api/files/remove-files", {fileIds}, getRequestHeaders())
      .then(() => {
        if (fileIds.length > 1) {
          toast.success("Fisierele au fost sterse cu succes");
        } else {
          toast.success("Fisierul a fost sters cu succes");
        }
        setFiles(prevFiles => {
          const newFiles = [...prevFiles].filter(({ _id }) => !fileIds.includes(_id));
          return newFiles;
        });
        setSelectedRows(new Set());
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingActions(false);
      });
  }, []);

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback((event) => {
    const { type, key } = event;

    if(type === 'click' || (type === 'keypress' && key === 'Enter')) {
      if (searchValue && searchValue.length >= 3) {
        setSearchFlag(true);
        setSearchError('');
        listFiles(currentPath, searchValue);
      } else if (!searchValue) {
        setSearchFlag(false);
        setSearchError('');
        listFiles(currentPath, searchValue);
      } else {
        setSearchError('Introduceti cel putin 3 caractere.');
      }
    }
  }, [currentPath, searchValue]);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span>
            <h3>
              Fisiere ({files.length}) [{Math.round(totalSize / 1000)}Kb] &gt;&nbsp;
              <span key={`file-route-key-${0}`} className="span-pointer" onClick={onFolderHistoryClick.bind(null, 0, moveFlag, copyFlag)}>/home</span>
              {currentPathNames.map((chunk, id) => (
                <span key={`file-route-key-${id + 1}`} className="span-pointer" onClick={onFolderHistoryClick.bind(null, id + 1, moveFlag, copyFlag)}>/{chunk}</span>
              ))}
            </h3>
          </span>
          <span>
            {
              selectedRows.size ? (
                <>
                  {
                    moveFlag || copyFlag ? (
                      <>
                        <Button
                          key="button-paste"
                          id="button-paste"
                          className="mr-2"
                          disabled={loadingActions}
                          color="success"
                          onClick={onPasteClick.bind(null, copyFlag, moveFlag, [...selectedRows], currentPath)}
                        >
                          <BsClipboardCheck />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="button-paste"
                          >
                            Lipire
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          key="button-cancel"
                          id="button-cancel"
                          className="mr-2"
                          disabled={loadingActions}
                          color="secondary"
                          onClick={onCancelClick}
                        >
                          <BsXLg />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="button-cancel"
                          >
                            Renuntare
                          </UncontrolledTooltip>
                        </Button>
                      </>
                    ) : (
                      <>
                        {
                          isAdminFlag ? (
                            <Button
                              key="button-remove"
                              id="button-remove"
                              className="mr-2"
                              disabled={loadingActions}
                              color="danger"
                              onClick={onRemoveClick.bind(null, [...selectedRows])}
                            >
                              <BsTrashFill />
                              <UncontrolledTooltip
                                placement="bottom"
                                target="button-remove"
                              >
                                Stergere
                              </UncontrolledTooltip>
                            </Button>
                          ) : null
                        }
                        <Button
                          key="button-move"
                          id="button-move"
                          className="mr-2"
                          disabled={loadingActions}
                          color="warning"
                          onClick={onMoveClick.bind(null, parentId)}
                        >
                          <BsScissors />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="button-move"
                          >
                            Mutare
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          key="button-copy"
                          id="button-copy"
                          className="mr-2"
                          disabled={loadingActions}
                          color="success"
                          onClick={onCopyClick.bind(null, parentId)}
                        >
                          <BsFiles />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="button-copy"
                          >
                            Copiere
                          </UncontrolledTooltip>
                        </Button>
                      </>
                    )
                  }
                </>
              ) : null
            }

            <Button
              id="button-add-folder"
              disabled={loading || loadingActions}
              className="mr-2"
              color="primary"
              onClick={openAddDir}
            >
              <BsFolderPlus />
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target="button-add-folder"
            >
              Adaugare Director
            </UncontrolledTooltip>
            <Modal
              isOpen={addDirOpen}
              toggle={toggleAddDir}
            >
              <ModalHeader toggle={toggleAddDir}>
                Adaugare Director
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="dir">Folder</Label>
                  <Input
                    type="text"
                    name="dir"
                    onChange={onDirInput}
                    multiple
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={closeAddDir}
                >
                  Renuntare
                </Button>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmitAddDir}
                  disabled={loadingActions}
                >
                  Salvare
                </Button>
              </ModalFooter>
            </Modal>

            <Button
              id="button-add-file"
              disabled={loading || loadingActions}
              color="primary"
              onClick={openAddFiles}
            >
              <BsFileEarmarkPlus />
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target="button-add-file"
            >
              Adaugare Document
            </UncontrolledTooltip>
            <Modal
              isOpen={addFilesOpen}
              toggle={toggleAddFiles}
            >
              <ModalHeader toggle={toggleAddFiles}>
                Adaugare Document
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="files">Fisier</Label>
                  <Input
                    type="file"
                    name="files"
                    onChange={onFilesInput}
                    multiple
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={closeAddFiles}
                >
                  Renuntare
                </Button>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmitAddFiles}
                  disabled={loadingActions}
                >
                  Salvare
                </Button>
              </ModalFooter>
            </Modal>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyPress={handleSearch}
              value={searchValue}
              invalid={Boolean(searchError)}
            />
            <Button
              disabled={loadingActions || loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
            <FormFeedback>{searchError}</FormFeedback>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                {
                  !searchFlag ? (
                    <th>
                      <Input className="select-row" type="checkbox" disabled={Boolean(moveFlag || copyFlag)} onChange={onSelectAllRows} checked={files.length !== 0 && selectedRows.size === files.length}/>
                    </th>
                  ) : null
                }
                <th colSpan={searchFlag ? 2 : 1}>Denumire</th>
                <th>User Autor</th>
                <th className="text-center">Descarcare</th>
                <th className="text-center">Redenumire</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spinner color="dark" style={{ width: "3rem", height: "3rem" }} />
                  </td>
                </tr>
              ) : [
                currentPath.length && !searchFlag ? (
                  <tr key="files-row-parent" onDoubleClick={searchFlag ? noOp : onParentDoubleClick.bind(null, moveFlag, copyFlag)}>
                    <td><BsArrowBarUp /></td>
                    <td colSpan={4}>
                      ...
                    </td>
                  </tr>
                ) : null,
                getRows()
              ]}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal
        isOpen={Boolean(renameFileOpen)}
        toggle={closeRenameFile}
      >
        <ModalHeader toggle={closeRenameFile}>
          Redenumire Document
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Denumire</Label>
            <Input
              type="text"
              name="name"
              onChange={onRenameInput}
              value={renameInput}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            color="secondary"
            className="float-left"
            onClick={closeRenameFile}
          >
            Renuntare
          </Button>
          <Button
            color="primary"
            className="float-right"
            onClick={onSubmitRenameFile}
            disabled={loadingActions}
          >
            Salvare
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
});

export default Files;
