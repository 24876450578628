// Packages
import React from "react";
import { Container, Row, Col } from "reactstrap";
// Styling
import "./NotFound.css";

const NotFound = React.memo((props) => (
  <Container className="mt-5 mb-5 text-center">
    <Row className="mt-5">
      <Col className="mt-5">
        <h4 className="mt-3">
          Pagina cautata nu a putut fi gasita.
        </h4>
      </Col>
    </Row>
  </Container>
));

export default NotFound;
