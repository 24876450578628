import axios from "axios";
import { headers } from ".";

const audience = process.env.REACT_APP_AUDIENCE;

export default function setAudienceHeader(){
  axios.defaults.headers.get[headers.audience] = audience;
  axios.defaults.headers.post[headers.audience] = audience;
  axios.defaults.headers.put[headers.audience] = audience;
  axios.defaults.headers.delete[headers.audience] = audience;
  axios.defaults.headers.patch[headers.audience] = audience;
};
