

// Packages
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Pagination as PaginationContainer, PaginationItem, PaginationLink } from "reactstrap";

const Pagination = React.memo((props) => {
  const { currentPage, totalCount, onSetPage } = props;
  const pages = useMemo(() => Math.max(Math.ceil(totalCount / 10), 1), [totalCount]);

  const onPageClick = useCallback((index, event) => {
    event.preventDefault();
    onSetPage(index);
  }, []);

  const headItems = useMemo(() => [
    (
      <PaginationItem key="pagination-item-key-first-arrow">
        <PaginationLink
          first
          href="#"
          onClick={onPageClick.bind(null, 1)}
        />
      </PaginationItem>
    ),
    (
      <PaginationItem key="pagination-item-key-previous-arrow">
        <PaginationLink
          href="#"
          previous
          onClick={onPageClick.bind(null, Math.max(currentPage - 1, 1))}
        />
      </PaginationItem>
    )
  ], [currentPage]);
  const tailItems = useMemo(() => [
    (
      <PaginationItem key="pagination-item-key-next-arrow">
        <PaginationLink
          href="#"
          next
          onClick={onPageClick.bind(null, Math.min(currentPage + 1, pages))}
        />
      </PaginationItem>
    ),
    (
      <PaginationItem key="pagination-item-key-last-arrow">
        <PaginationLink
          href="#"
          last
          onClick={onPageClick.bind(null, pages)}
        />
      </PaginationItem>
    )
  ], [currentPage, pages]);
  const [items, setItems] = useState([...headItems, ...tailItems]);

  useEffect(() => {
    setItems(prevItems => {
      const currentItems = [];
      if (pages > 5) {
        if (currentPage < 5) {
          for (let i = 1; i <= 5; i++) {
            currentItems.push(
              <PaginationItem key={`pagination-item-key-${i}`} active={i === currentPage}>
                <PaginationLink href="#" onClick={onPageClick.bind(null, i)}>
                  {i}
                </PaginationLink>
              </PaginationItem>
            );
          }
          currentItems.push(
            <PaginationItem key="pagination-item-key-second-last" disabled={true}>
              <PaginationLink href="#">
                ...
              </PaginationLink>
            </PaginationItem>
          );
          currentItems.push(
            <PaginationItem key="pagination-item-key-last" active={false}>
              <PaginationLink href="#" onClick={onPageClick.bind(null, pages)}>
                {pages}
              </PaginationLink>
            </PaginationItem>
          );
        } else
        if (currentPage > pages - 4) {
          currentItems.push(
            <PaginationItem key="pagination-item-key-first" active={false}>
              <PaginationLink href="#" onClick={onPageClick.bind(null, 1)}>
                {1}
              </PaginationLink>
            </PaginationItem>
          );
          currentItems.push(
            <PaginationItem key="pagination-item-key-second" disabled={true}>
              <PaginationLink href="#">
                ...
              </PaginationLink>
            </PaginationItem>
          );
          for (let i = pages - 4; i <= pages; i++) {
            currentItems.push(
              <PaginationItem key={`pagination-item-key-${i}`} active={i === currentPage}>
                <PaginationLink href="#" onClick={onPageClick.bind(null, i)}>
                  {i}
                </PaginationLink>
              </PaginationItem>
            );
          }
        } else {
          currentItems.push(
            <PaginationItem key="pagination-item-key-first" active={false}>
              <PaginationLink href="#" onClick={onPageClick.bind(null, 1)}>
                {1}
              </PaginationLink>
            </PaginationItem>
          );
          currentItems.push(
            <PaginationItem key="pagination-item-key-second" disabled={true}>
              <PaginationLink href="#">
                ...
              </PaginationLink>
            </PaginationItem>
          );
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            currentItems.push(
              <PaginationItem key={`pagination-item-key-${i}`} active={i === currentPage}>
                <PaginationLink href="#" onClick={onPageClick.bind(null, i)}>
                  {i}
                </PaginationLink>
              </PaginationItem>
            );
          }
          currentItems.push(
            <PaginationItem key="pagination-item-key-second-last" disabled={true}>
              <PaginationLink href="#">
                ...
              </PaginationLink>
            </PaginationItem>
          );
          currentItems.push(
            <PaginationItem key="pagination-item-key-last" active={false}>
              <PaginationLink href="#" onClick={onPageClick.bind(null, pages)}>
                {pages}
              </PaginationLink>
            </PaginationItem>
          );
        }
      } else {
        for (let i = 1; i <= pages; i++) {
          currentItems.push(
            <PaginationItem key={`pagination-item-key-${i}`} active={i === currentPage}>
              <PaginationLink href="#" onClick={onPageClick.bind(null, i)}>
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }
      }
      return [...headItems, ...currentItems, ...tailItems];
    });
  }, [pages, currentPage, headItems, tailItems]);

  return (
    <PaginationContainer listClassName="justify-content-center">
      {items}
    </PaginationContainer>
  );
});

export default Pagination;
