import axios from "axios";
import { headers } from ".";

export default function setCsrfHeaders(csrfToken){
  axios.defaults.headers.get[headers.csrf] = csrfToken;
  axios.defaults.headers.post[headers.csrf] = csrfToken;
  axios.defaults.headers.put[headers.csrf] = csrfToken;
  axios.defaults.headers.delete[headers.csrf] = csrfToken;
  axios.defaults.headers.patch[headers.csrf] = csrfToken;
};
